import React from "react";
import Swede1 from "./images/swede1.jpg";
import Swede2 from "./images/swede2.jpg";
import "./style.css";



export default function History(props) {

    return (
        <div className="Continer History">
            <h3 className="text-center">Why our farm is called "Swede Farm"</h3>
            <div className="d-flex justify-content-center">
                <img src={Swede1} className="p-2 img-fluid flexImg swede"></img>
                <img src={Swede2} className="p-2 img-fluid flexImg swede"></img>
            </div>
            <p>In 2003 we lost someone very special to us.  Arvid Carlson epitomized the principles of doing the right thing and rising above individual desires.  Drafted in February 1943 he served his country honorably for many years.  As a Naval Seaman he saw some of the bloodiest battles of WWII in the south Pacific.  He returned home to a thankful family and set about rebuilding his life only to be  sent overseas again during the Korean War.  When he was able to return to civilian life he found the doors open to him previously, now closed.  Instead of allowing this to make him bitter, he simply rolled up his sleeves and went to work as a tool and die maker.  This is a pattern that continued his entire life.  Although this life of principled service would never attract the attention of the world, seeming mundane and unremarkable, those who know and loved "Swede" as he was called, knew him to be a man of deep worth and hidden talents.  His love for photography chronicles a life that is both singular and representative.  In Swede's life and photographs we read the story of a generation of self-sacrificing men of integrity who did the right thing even when it hurt, even when it was to their own personal loss.  It was through Swede's hard work and diligence that we were able to follow our dream and purchase our farm.  Our lives were better for having loved him in life and forever changed by the provision he made for his family at his death.</p>
            <p>Arvid graduated from Gustavus Adolphus College with a degree in economics.  He served as a torpedo man in the Navy.  In WWII he served on the HL Edwards during the Saipan-Tinian operatons, Palau, Surigao Straight, Iwo Jima and Okinawa.  During the Korean War he served on the USS Dixie. Later in his life he volunteered many hours at the Houston VA hospital.  He is buried in the Houston National Cemetery</p>
        </div>
    )
}