import React from "react";
import "./style.css";



export default function Contact(props) {

    return (
        <div></div>
    )
}