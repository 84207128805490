import React from "react";
import "./style.css";



export default function Footer(props) {

    return (
        <footer className="footer"> 
        <div className="container">
        <span>Copyright <i class="fa fa-copyright"></i> Swede Farm Dairy</span>
        <span>&nbsp;&nbsp;</span>
        <span><a href="mailto:swedefarm@gmail.com"><i class="fas fa-envelope-square" aria-hidden="true"></i></a></span>
        <span>&nbsp;&nbsp;</span>
        <span><a href="https://www.facebook.com/swedefarmdairy" target="_blank" rel="noopener noreferrer" ><i class="fab fa-facebook-square" aria-hidden="true"></i></a></span>
        <span>&nbsp;&nbsp;</span>
        <span><a href="tel:8326597070" target="_blank" rel="noopener noreferrer" ><i class="fa fa-phone-square" aria-hidden="true"></i></a></span>
        </div>
        </footer>
 
    )
}