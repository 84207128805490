import React from "react";
import Dairy1 from "./images/dairy1.jpg";
import Dairy2 from "./images/dairy2.jpg";
import Dairy3 from "./images/dairy3.jpg";
import Dairy4 from "./images/dairy4.jpg";
import Dairy5 from "./images/dairy5.jpg";
import "./style.css";



export default function Dairy(props) {

    return (
        <div className="Container Dairy">
            <div className="d-flex flex-wrap flex-lg-nowrap">
                <img src={Dairy3} className="p-2 img-fluid flexImg"></img>
                <img src={Dairy2} className="p-2 img-fluid flexImg"></img>
                <p className="align-self-center">Our does are milked twice a day with as much care and attentiveness as we can possibly give to them.  Pictured here are shots of our dairy facility.  The building comprises two rooms.  The room to the right is the milking parlor and the room to the left is for processing the milk.  In the parlance of Texas law, the first room is the "Dairy" and the second is the "Milk Plant"</p>
            </div>
            <br></br>
            <p>The milking process begins when the does are brought in from their pens to the holding area.  This cement area is designed to minimize the amount of soil that the goats have on their hooves.  The does are let in through a small sliding door.  They are milked on the milk stantion, four at a time, while they chow down their morning or evening meal.  When the does are finished eating and being milked we let them back out to their pens, but not before they each recieve their animal cookie as a treat.</p>
            <br></br>
            <div className="d-flex flex-wrap flex-lg-nowrap">
                <img src={Dairy4} className="p-2 img-fluid flexImg"></img>
                <img src={Dairy5} className="p-2 img-fluid flexImg"></img>
                <p className="align-self-center">The next part of the process is to pasteurize the milk according to Texas law. Milk is vat pasteurized in the droid-looking micropasteurizer pictured above.  This pasteurizer was developed by <a href="mailto:frank@kipe.com">Frank Kipe</a>  of MicroDairy Designs LLC in Maryland.  Temperatures are recorded on the chart recorder hanging on the wall.  The milk is cooled down with chilled water from the bulk tank cooler and stored in bottles ready to be sold at market.</p>
            </div>
            <br></br>
            <p>We have come a long way since we cemented the floors of our old barn and framed out the walls.  Bringing local milk to market in Houston as a family is a rewarding experience.  We hope to see you soon at market or at our farm.</p>
            <br></br>
        </div>
    )
}