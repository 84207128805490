import React from "react";
import verticalCheese from "./images/verticalcheese.jpg";
import "./style.css";



export default function Products(props) {

    return (
        <div className="container-fluid Products">
            <div className="row">
            <div className="col-xs-1 col-sm-1 col-md-4">
                <img src={verticalCheese} className="verticalCheese img-fluid"></img>
            </div>
            <div className="col-xs-1 col-sm-1 col-md-6 mx-auto">
            <div className="Product">
            <h2 className="text-center mb-2 mt-2">Products</h2>
            <p className="text-center">At Swede Farm we are passionate about creating an artisanal product that is one of a kind.  We believe that our handcrafted cheeses and yogurts are truly exceptional.  We take the family farm to a new level, from caring for the animals to milking the goats, pasteurizing and bottling our wonderfully fresh milk to creating our one-of-a-kind cheeses, all is done by a member of the family.  This extends to market, where you can be assured that the person you purchase your product from has actually played a direct role in bringing that product to market and can speak knowledgeably about the farm and what we do there.  We believe that what we do is truly unique and we invite you to enjoy our handiwork--we think it is really something quite special.</p>
            </div>
            <div className="Product">
            <h3 className="text-center"><u>Milk: $10 Half Gallon $6 Quart</u></h3>  
            <p className="text-center">Our milk is naturally non-homogenized and low temperature pasteurized.  Our milk is hand-bottled and sold by the quart and half gallon.</p> 
            </div>
            <div className="Product">
            <h3 className="text-center"><u>Chevre: $10 Half Pound</u></h3>  
            <p className="text-center">Chevre--the traditional fresh goat cheese, our chevre is crumbly when cold and spreadable at room temperature.  Because these cheeses are fresh you will often note seasonal variations in the texture of the cheese; during times of the year when the goats produce creamier milk the cheeses are often more dense and spreadable.  Besides the plain chevre (with only a touch of sea salt) we also carry several flavors--a spicy with a habanero kick, garlic and chive, smoky (plain with a smoked sea salt) and lemon-blueberry that tastes like cheesecake.  </p> 
            </div>
            <div className="Product">
            <h3 className="text-center"><u>Feta: $10 Six Ounces</u></h3>  
            <p className="text-center">This is another fresh cheese. It is drier and slightly saltier than chevre and is great in any dish where you want the cheese to remain in distinct crumbles as opposed to melting.</p> 
            </div>
            <div className="Product">
            <h3 className="text-center"><u>Yogurt: $5 Pint</u></h3>  
            <p className="text-center">Due to the protein and fat structure of goat milk, goat milk yogurt makes a drinkable rather than spoonable product.  We could add a thickening agent such as tapioca starch or pectin but prefer to keep things as simple as we can so to allow for the natural goodness of the yogurt to shine through.  All that is in our plain yogurt is our milk and five active cultures.  The vanilla bean yogurt has a vanilla bean paste added to it to give it a subtle flavor without being overly sweet.  We do add a seasonal yogurt to the line-up during the early summer months of ginger-peach.  Our yogurts make a great breakfast on the run.</p> 
            </div>
            <div className="Product">
            <h3 className="text-center"><u>Kefir: $5 Pint</u></h3>  
            <p className="text-center">Kefir is a traditionally fermented drink made from milk.  Because it is fermented rather than cultured it actually develops a carbonation.  Tasting like buttermilk when fresh and almost like a good wheat beer when aged a few days. Kefir is exceptionally high in probiotics.</p> 
            </div>
            <div className="Product">
            <h3 className="text-center"><u>Chocolate Milk: $5 Pint</u></h3>  
            <p className="text-center">Taking the natural sweet creaminess of goat milk to a whole new decadent level, our chocolate milk combines one whole pound of ground chocolate per gallon of our milk to create a dark chocolate treat that has to be experienced to be believed.  We use no emulsifiers to keep the chocolate well blended so it important to shake the milk well before drinking it--unless the chocolate cream at the top is your favorite part.</p> 
            </div>
            <div className="Product">
            <h3 className="text-center"><u>Chocolate Truffles: $12 per Container </u></h3>  
            <p className="text-center">Truffles are available from Thanksgiving through New Years Day.These intensely chocolate candies, made with our chevre rolled in dark chocolate, taste like cheesecake.  They make a wonderful addition to a cheese tray or plate of cookies and make fantastic gifts.</p> 
            </div>
            </div>
            </div>
        </div>
    )
}