import React from "react";
import Family from "./images/CarlsonFamily.jpeg"
import MilkCheese from "./images/milkcheese.jpg"
import "./style.css";




export default function First(props) {

    return (
        <div className="First Container">
            <img src={Family} className="firstPic img-fluid mb-2"></img>
            <p>Our intention in starting Swede Farm Dairy was to provide our family with the freshest, best tasting dairy products to be had.  We wanted more for our table than mass produced factory food, we wanted wholesome food that we could take responsibility for.  We wanted a way to return to a time when families were intricately connected with what they ate and drank.  We found a deep sense of satisfaction in being able pour a glass of cold milk for our children and know exactly how that milk was made, what the goats ate, how they lived, and the work that went into getting the milk from the pasture to the table.</p>
            <img src={MilkCheese} className="milkCheese img-fluid"></img>
            <p>Now we want to bring that milk from our farm to your table.  While it may not be quite the same as milking the goat yourself, we think it is something pretty special to be able to drink milk produced locally from goats that you can come out and pet!  We work hard to provide products that are fresh and minimally processed.  We believe in allowing the quality of the milk itself to be the "star" in what we bring to market.  This is behind our commitment to leave our products as simple as they can be.  We add no unnecessary thickeners, fillers or preservatives to our yogurt or cheese in order to let the pure wholesome goodness of our fresh milk stand out. </p>
            <p>In short, we treat every single product as if it is destined for our own table, for our own children - because if we did not feel comfortable feeding it to our children we would not want it set before anyone else's children.  This is what drives us at Swede Farm Dairy - a labor of love from our hands to yours.</p>
        </div>
    )
}