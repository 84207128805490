import React, { useState } from "react";
import "./style.css";
import MapPic from "./images/mappicarrow.png";
import Background from "./images/dark-wood.png"



export default function Mapto(props) {

const [mapFrom, setmapFrom] = useState();


const display = (mapFrom) => {
    switch(mapFrom) {
        case 'Austin':
            return(
                <div>
                <p>Swede Farm is a 2 hour and 15 minute drive from Austin</p>
                <p>xFrom Austin head east on 290 towards Houston</p>
                <p>When you get to Hempstead head north on HWY 6</p>
                <p>Drive north 6.6 miles and turn right on Farm Road 2979</p>
                <p>Stay on Farm Road 2979 for 7.7 miles until you come to the dead end</p>
                <p>Turn left on Farm Road 362 for 0.6 miles</p>
                <p>Turn right on Reids Prairie Road</p>
                <p>Turn right at the third street on right (Clark Road)</p>
                <p>Go 0.3 miles to dead end and turn left on W Brooks Road</p>
                <p>Swede Farm is the third property on the right.</p>
                </div>
            )
        case 'Hempstead':
            return(
                <div>
                    <p>From the intersection of 290 and fm 1488 go east 5 miles towards Magnolia on fm 1488</p>
                    <p>At the stop light (Prairie View to the right) turn left</p>
                    <p>Go 9 miles to Field Store</p>
                    <p>When you get to San Bernard Electric Coop turn left on Bowler Road.</p>
                    <p>Proceed north on Bowler 2.8 miles until the road comes to a dead end.</p>
                    <p>Note the sign in front of you that says "Put a Longhorn in your yard"</p>
                    <p>Turn right onto Kyle Road.</p>
                    <p>Proceed 100 yards and take a left at the first street which is Brooks Road.</p>
                    <p>Proceed .5 miles on Brooks Road which will curve to the right, left and left again</p>
                    <p>Brooks will dead end into Newland.</p>
                    <p>Take a left on Newland which will immediately turn to the right</p>
                    <p>Swede Farm is 1/4 mile down on the left just past Thompson Road</p>
                </div>
            )
        default:
            return(
                <div></div>
            )
    }
}


const style = {backgroundImage: {Background}}

    return (
        <div style={style} className="Map imageContainer">
        <h3 className="text-center">Directions from Where to Swede Farm?</h3>
        <div className="d-flex justify-content-center">
            <p className="location p-2" onClick={() => setmapFrom('Austin')}>Austin</p>
            <p className="location p-2" onClick={() => setmapFrom('Hempstead')}>Hempstead</p>
        </div>
        {display(mapFrom)}
        <img src={MapPic} className="img-fluid mx-auto" alt="Responsive image"></img>
    </div>
    )
}