import React,{ useState } from 'react';
import {BrowserRouter as Router, Route} from "react-router-dom";
import "./style.css";
import First from "./First";
import Dairy from "./Dairy";
import Header from "./Header";
import Footer from "./Footer";
import History from "./History";
import Mapto from "./Map";
import Contact from "./Contact";
import Products from "./Products";
import Background from "./images/dark-wood.png";
import Background2 from "./images/debut_light.png"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';


export default function App() {

  const [modalShow, setModalShow] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const newToggle = () => setModalShow(!modalShow);
  const toggle = () => setIsOpen(!isOpen);
  

    const sectionStyle = {
      backgroundImage: `url(${Background2})`,
      backgroundColor: "#FBEEC1"
    };
    const modalStyle = {
      backgroundColor: "#556b2f",
      color: "white",
      border: "none",
      fontFamily: "Lily Script One"
      };
    const modalStyle2 = {
      backgroundColor: "#FBEEC1",
      color: "#556b2f",
      border: "none",
      textDecoration: "none"
    };
    const modalButton = {
      backgroundColor: "white",
      color: "#3d3d3d",
    }

  return (
    <Router>
      <div>
            <Header className="sticky-top" toggle={toggle} isOpen={isOpen} newToggle={newToggle}  />
            <div className="middle">
              <Route exact path="/" component={First} />
              <Route exact path="/dairy" component={Dairy} />
              <Route exact path="/history" component={History} />
              <Route exact path="/products" component={Products} />
              <Route exact path="/map" component={Mapto} />
              <Route exact path="/Contact" component={Contact} />
            </div>
            <Footer />
            <Modal toggle={newToggle}  isOpen={modalShow}  >
            <ModalHeader toggle={newToggle} style={modalStyle}>Contact Info</ModalHeader>
            <ModalBody style={modalStyle2}>
              <p>Contact Swede Farm With Any Questions</p>
              <p><a href="mailto:swedefarm@gmail.com"><i className="fas fa-envelope-square" aria-hidden="true"></i>&nbsp; &nbsp; swedefarm@gmail.com</a></p>
              <p><a href="https://www.facebook.com/swedefarmdairy" target="_blank" rel="noopener noreferrer" ><i className="fab fa-facebook-square" aria-hidden="true"></i>&nbsp; &nbsp; Facebook</a></p>
              <p><a href="tel:8326597070" target="_blank" rel="noopener noreferrer" ><i className="fa fa-phone-square" aria-hidden="true"></i>&nbsp; &nbsp; 832-659-7070</a></p>
            </ModalBody>
            <ModalFooter style={modalStyle}>
              <Button style={modalButton} onClick={newToggle}>Close</Button>
            </ModalFooter>
          </Modal>
      </div>
    </Router>
    );
  }




