import React, { useState } from "react";
import "./style.css";
import { Link } from "react-router-dom";
import { Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    NavbarText,
     } from 'reactstrap';


export default function Header(props) {
const style = {
    textDecoration:"none",
    fontFamily: 'Rock Salt',
    color: '#556b2f',
    fontSize: '1.5rem'
}
const togglerStyle = {
    backgroundColor: "#FBEEC1",
    color: "black"
}



    return (
        <header className="header">
            <Navbar className="navbar navbar-expand-lg navbar-light">
                <NavbarBrand href="/" style={style}>Swede Farm Dairy</NavbarBrand>
                <NavbarToggler onClick={props.toggle} style={togglerStyle} />
                <Collapse isOpen={props.isOpen} navbar>
                <Nav className="ml-auto" navbar>
                    <NavItem>
                            <NavLink href="/dairy" className={window.location.pathname === "/dairy" ? "nav-link active" : "nav-link"}>Dairy</NavLink>
                    </NavItem>
                    <NavItem>
                            <NavLink href="/products" className={window.location.pathname === "/products" ? "nav-link active" : "nav-link"}>Products</NavLink>
                    </NavItem>
                    <NavItem className="nav-item">
                            <NavLink href="/history" className={window.location.pathname === "/history" ? "nav-link active" : "nav-link"}>History</NavLink>
                    </NavItem>
                    <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle nav caret>
                        Contact Us!
                    </DropdownToggle> 
                    <DropdownMenu right>
                        <DropdownItem onClick={props.newToggle} className="nav-link text-center navDrop">Contact</DropdownItem>
                        <DropdownItem><NavLink href="/map" className="nav-link navDrop">Map to Farm</NavLink></DropdownItem>
                    </DropdownMenu>
                    </UncontrolledDropdown > 
                </Nav>
                </Collapse>
            </Navbar>
        </header>
    )
}